/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable eqeqeq */
import React from 'react';
import { Upload } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { FaDownload } from "react-icons/fa6";
import { MdCloudUpload } from "react-icons/md";
import IMAGE from "../../../images/ImageDefault.png"
import { downloadFile, getIconPath, hasFileExtension } from '../../../Utils/helpers';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';

const imageExt = ['jpg', 'jpeg', 'png', 'gif', 'webp', 'bmp']
const fileExt = ['pdf', 'doc', 'docx', 'ppt', 'pptx', "txt", "csv", "xls", "xlsx", "mp4", "eps", "ai", 'odt', 'tif']

const renderIcon = (file) => {
  let fileType = file.type?.split("/")[0]
  const fileExtension = file.name.split('.').pop().toLowerCase()
  if (file.status == "done" && imageExt.includes(fileExtension)) {
    return <img src={file?.url ? file?.url : IMAGE} />
  } else if (file.status == "done" && fileExt.includes(fileExtension)) {
    return <img src={`/static/icons/${getIconPath(file.name)}`} />
  } else if ((file.status == "uploading" || file.status == "error") && fileType != "image") {
    return <img src={`/static/icons/${getIconPath(file.name)}`} />
  } else {
    return <img src={IMAGE} />
  }
}

const CustomerDocUpload = (props) => {
  const handleRemove = (file) => {
    props.handleRemove(file)
  };

  const handleDownload = (file) => {
    props.handleDownload(file)
  };

  const openDocument = (file) => {
    if(file?.name && file?.url){
      window.open(`/view-document?name=${encodeURIComponent(file.name)}&url=${encodeURIComponent(file.url)}`, '_blank', 'noopener noreferrer');
    }
  };

  const handleClick = (event, file) => {
    event.preventDefault();
    if (file) {
      let hasExt = hasFileExtension(file?.url)
      if (!hasExt) {
        window.alert('Files from the old systems cannot be opened within this system, so they need to be downloaded.')
      } else {
        openDocument(file)
      }
    }
  };

  const renderLinkTag = (file) => {
    const fileExtList= ['jpg', 'jpeg', 'png', 'gif', 'webp', 'pdf', 'doc', 'docx', 'ppt', 'pptx', "xls", "xlsx", 'odt', 'htm', 'html', 'txt', 'mp4']
    const fileExtension = file.name.split('.').pop().toLowerCase()

    if (fileExtList.includes(fileExtension)) {
      return <a href={file.url} title={file.name} target="_blank" onClick={(e) => handleClick(e, file)} rel="noopener noreferrer">{file.name}</a>
    } else {
      return <a href={file.url} download={file.name} title={file.name} target="_blank" rel="noopener noreferrer">{file.name}</a>
    }
  }

  const customItemRender = (originNode, file, fileList) => {
    return (
      <div className={`custom-file-item ${file.status == "error" ? "file-error" : ""}`}>
        <div className="custom-file-info">
          <div className='d-flex align-items-center upload-file-name'>
            <span className="custom-img-thumb">
              {file?.status == 'uploading' ? <div className='upload-spinner'>
                <Spin indicator={<LoadingOutlined style={{ fontSize: 24, fill: '#fff', color: "#2c0036" }} spin />} />
              </div> : renderIcon(file)}
            </span>
            {renderLinkTag(file)}
          </div>
          <div className='d-flex gap-3'>
            <span className="custom-icon" onClick={() => handleRemove(file)}>
              <DeleteOutlined />
            </span>
            <span className="custom-icon" onClick={() => handleDownload(file)}>
              <FaDownload />
            </span>
          </div>
        </div>
        {(file.status == "error" && file?.error?.message) && <span className='file-error-text'>{file?.error?.message}</span>}
      </div>
    );
  };

  return (
    <Upload.Dragger
      multiple={props.multiple ? props.multiple : false}
      customRequest={props.customRequest}
      fileList={props.fileList}
      itemRender={customItemRender}
      style={{ background: 'white' }}
      onChange={props.onChange}
      accept={props.accept ? props.accept : ""}
    >
      <p className="ant-upload-drag-icon">
        <MdCloudUpload />
      </p>
      <p className="ant-upload-text">Click or drag file to this area to upload</p>
    </Upload.Dragger>
  );
};

export default CustomerDocUpload;
